@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,500;0,600;0,900;1,500&display=swap');
@import '../../variables';


@media screen and (min-width: 768px) {
    #index-carrousel{
        height: 100vh;
        width: 100%;
        .carrousel-arrow{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            color: #fff;
            font-size: 20px;
            cursor: transition;
            &.carrousel-arrow-left{
                left: 3%;
            }
            &.carrousel-arrow-right{
                right: 3%;
            }
        }
        .carousel-img-containter{
            height: 100vh;
            width: 100%;
            position: absolute;
            top: 0;
            display: inline-block;
            .row{
                height: 100vh;
                margin: 0;
                padding: 0;
                .carousel-home-img{
                    height: 100vh;
                    margin: 0;
                    padding: 0;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        .carousel-first{
            left: 0%;
        }
        .carousel-others{
            left: 100%;
        }
    }
    #categories-container{
        width: 100%;
        h3 {
            margin:30px 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: #1d1d1d;
        }
        .collage-img-container{
            .collage-cat-img {
                padding: 0;
                height: 60vh;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border: 5px solid #fff;
                cursor: pointer;
                transition: background-size 0.3s ease-in-out;
            }
            .text-overlay {
                position:relative;
                bottom: 0;
                right: 0;
                background-color: rgba(255, 255, 255, 0.336);
                padding: 12px;
                color: $gray-primary;
              }
              
              .text-overlay h4 {
                margin: 0;
                text-align: right;
                font-size: 0.7rem;
                font-weight: 400;
              }
        }
        
    }
    #brands-container{
        margin: 3rem 0;
        h3 {
            margin:30px 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: #1d1d1d;
        }
        #brand-carousel-container{
            height: fit-content;
            #brand-carousel-arrow-left,
            #brand-carousel-arrow-right{
                cursor: pointer;
                width: min-content;
                font-size: 1.5em;
                color: $gray-secondary;
            }
            #brand-carousel-list{
                width: 100%;
                height: 100%;
                list-style: none;
                vertical-align: middle;
                white-space: nowrap;
                overflow-x: auto;
                transition: .3s;
                &::-webkit-scrollbar {
                    display: none; /* Hide scrollbar on Chrome and Safari */
                }
                .brand-carousel-list-item{
                    cursor: pointer;
                    transition: .3s;
                    opacity: .6;
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 0 12px;
                    &:hover{
                        opacity: 1;
                    }
                    .brand-carousel-img{
                        height:100px;
                        width: 100px;
                        object-fit: contain;
                        object-position: center;
                    }  
                    .brand-carousel-name{
                        width: 100%;
                        margin: 5px 0 0 0;
                        padding: 0;
                        text-align: center;
                    }   
                }
            }
        }
    }
    #arrivals-container{
        margin: 3rem 0;
        height: 70vh;
        h3 {
            margin:30px 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: #1d1d1d;
        }
        #arrival-carousel-container{
            height: fit-content;
            #arrival-carousel-arrow-left,
            #arrival-carousel-arrow-right{
                cursor: pointer;
                width: min-content;
                font-size: 1.5em;
                color: $gray-secondary;
            }
            .opacity-carousel-arrivals {
                height: 52vh;
                width: 30vh;
                position: relative;
                z-index: 1;
            }
            #opacity-left-carousel-arrivals {
                background: rgb(255,255,255);
                background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 10%, rgba(255,255,255,1) 100%);
                float: left;
            }
            #opacity-right-carousel-arrivals {
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 30%, rgba(255,255,255,1) 100%);
                float: right;
            }
            #arrival-carousel-list{
                width: 100%;
                height: 58vh;
                list-style: none;
                vertical-align: middle;
                white-space: nowrap;
                overflow-x: auto;
                transition: .3s;
                &::-webkit-scrollbar
                {
                    width: 100px;
                    height: 4px;
    
                    background-color: #000;
                }
                .arrival-carousel-list-item{
                    cursor: pointer;
                    transition: .3s;
                    width: 28vh;
                    padding-bottom: 1vh;
                    .arrival-carousel-img{
                        height:40vh;
                        width: 27vh;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        background-size: 100%;
                        transition: .3s;
                    }  
                    .arrival-carousel-name,
                    .arrival-carousel-brand,
                    .arrival-carousel-price,
                    .arrival-carousel-normal-price{
                        display: block;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        color: $gray-secondary;
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }   
                    .arrival-carousel-name {
                        font-weight: 500;
                        font-size: 1.2em;
                        letter-spacing: 0.5px;
                    }
                    .arrival-carousel-price {
                        font-weight: 600;
                        font-size: 1em;
                        letter-spacing: 0.5px;
                    }
                    .arrival-carousel-normal-price{
                        color: $discount-color !important;
                        text-decoration: line-through;
                        font-weight: 600;
                        font-size: .8em;
                        letter-spacing: 0.5px;
                    }
                    .arrival-carousel-brand{
                        font-weight: 400;
                        font-size: .9em;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
    }
    #the-store-container{
        background-color: #dfdacf;
        min-height: 70vh;
        padding: 8vh 0;
        #the-store-back-bg-img,
        #the-store-text-container{
            color: #222222;
            text-align: justify;
        }
        h1 {
            font-size: 1.95rem;
        }
        #the-store-back-bg-img{
            width: 28vw;
            height: 36vw;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        #the-store-text-container{
            width: 52%;
            margin-left: 5%;
            #the-store-title {
                margin: 15px 0;
            }
        }
       #the-store-text {
            line-height: 1.6rem;
            font-size: 0.9em;
            font-weight: 400;
            letter-spacing: 0.5px;
       } 
    }
    #blog-container{
        margin: 3rem 0 3rem 3rem;
        padding: 3rem 0;
        #blog-text-container{
            width: 46vw;
            margin-right: 5%;
            margin-left: 6vw;
        }
        #blog-back-bg-img{
            width: 28vw;
            height: 40vw;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 9vw;
        }
        #blog-text-container{
            #blog-title,
            #blog-author,
            #blog-text{
                color: $gray-secondary;
            }
            #blog-title{
                margin: 0;
                padding: 0;
                display: block;
                font-weight: 600;
                font-size: 1.95rem;
                text-align: left;
            }
            #blog-author{
                margin: 4vh 0 4vh 0;
                padding: 0;
                font-weight: 400;
                font-size: 1.2rem;
                text-align: left;
            }
            #blog-text {
                line-height: 1.6rem;
                font-size: 0.9rem;
                font-weight: 400;
                text-align: justify;
            }
            #blog-read-more{
                background-color: transparent;
                border: 1.6px solid $gray-secondary;
                color: $gray-secondary;
                padding: 12px 100px;
                font-weight: 500;
                cursor: pointer;
                transition: .3s;
                margin-top: 2vh;
                font-size: 1rem;
                &:hover{
                    transform: scale(1.05);
                }
            }
        }
    }
    
}
@media screen and (max-width: 768px) {
    #index-carrousel{
        height: 100vh;
        width: 100%;
        .carrousel-arrow{
            position: absolute;
            top: 4.5%;
            transform: translateY(-50%);
            z-index: 1;
            color: #fff;
            font-size: 20px;
            cursor: transition;
            &.carrousel-arrow-left{
                left: 3%;
            }
            &.carrousel-arrow-right{
                right: 3%;
            }
        }
        .carousel-img-containter{
            height: 100vh;
            width: 100%;
            position: absolute;
            top: 0;
            display: inline-block;
            .row{
                height: 100vh;
                margin: 0;
                padding: 0;
                .carousel-home-img{
                    height: 100vh;
                    margin: 0;
                    padding: 0;
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }
        }
        .carousel-first{
            left: 0%;
        }
        .carousel-others{
            left: 100%;
        }
    }
    #categories-container{
        width: 100%;
        h3 {
            margin:30px 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: #1d1d1d;
        }
        .collage-img-container{
            .collage-cat-img {
                padding: 0;
                height: 60vh;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                border: 5px solid #fff;
                cursor: pointer;
                transition: background-size 0.3s ease-in-out;
            }
            .text-overlay {
                position:relative;
                bottom: 0;
                right: 0;
                background-color: rgba(255, 255, 255, 0.336);
                padding: 12px;
                color: $gray-primary;
              }
              
              .text-overlay h4 {
                margin: 0;
                text-align: right;
                font-size: 0.7rem;
                font-weight: 400;
              }
        }
        
    }
    #brands-container{
        margin: 0.5rem 0;
        h3 {
            margin:30px 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: #1d1d1d;
        }
        #brand-carousel-container{
            height: fit-content;
            #brand-carousel-arrow-left,
            #brand-carousel-arrow-right{
                cursor: pointer;
                width: min-content;
                font-size: 20px;
                color: $gray-secondary;
            }
            #brand-carousel-list{
                width: 100%;
                height: 100%;
                list-style: none;
                vertical-align: middle;
                white-space: nowrap;
                overflow-x: auto;
                transition: .3s;
                &::-webkit-scrollbar {
                    display: none; /* Hide scrollbar on Chrome and Safari */
                }
                .brand-carousel-list-item{
                    cursor: pointer;
                    transition: .3s;
                    display: inline-flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 0 8px;
                    &:hover{
                        opacity: 1;
                    }
                    .brand-carousel-img{
                        height:60px;
                        width: 75px;
                        object-fit: contain;
                        object-position: center;
                    }  
                    .brand-carousel-name{
                        width: 100%;
                        margin: 5px 0 0 0;
                        padding: 0;
                        text-align: center;
                        font-size: 0.7rem;
                    }   
                }
            }
        }
    }
    #arrivals-container{
        margin: 0.5rem;
        height: 70vh;
        h3 {
            margin:30px 0;
            font-size: 1.2rem;
            font-weight: 400;
            color: #1d1d1d;
        }
        #arrival-carousel-container{
            height: fit-content;
            #arrival-carousel-arrow-left,
            #arrival-carousel-arrow-right{
                display: none;
            }
            .opacity-carousel-arrivals {
                display: none;
            }
            #opacity-left-carousel-arrivals {
                background: rgb(255,255,255);
                background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 10%, rgba(255,255,255,1) 100%);
                float: left;
            }
            #opacity-right-carousel-arrivals {
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 30%, rgba(255,255,255,1) 100%);
                float: right;
            }
            #arrival-carousel-list{
                width: 100%;
                height: 58vh;
                list-style: none;
                vertical-align: middle;
                white-space: nowrap;
                overflow-x: auto;
                transition: .3s;
                &::-webkit-scrollbar
                {
                    width: 100px;
                    height: 4px;
    
                    background-color: #000;
                }
                .arrival-carousel-list-item{
                    cursor: pointer;
                    transition: .3s;
                    width: 26vh;
                    padding-bottom: 1vh;
                    .arrival-carousel-img{
                        height:40vh;
                        width: 25vh;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;
                        background-size: 100%;
                        transition: .3s;
                    }  
                    .arrival-carousel-name,
                    .arrival-carousel-brand,
                    .arrival-carousel-price,
                    .arrival-carousel-normal-price{
                        display: block;
                        width: 100%;
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        color: $gray-secondary;
                        width: 100%;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }   
                    .arrival-carousel-name {
                        font-weight: 500;
                        font-size: 1.2em;
                        letter-spacing: 0.5px;
                    }
                    .arrival-carousel-price {
                        font-weight: 600;
                        font-size: 1em;
                        letter-spacing: 0.5px;
                    }
                    .arrival-carousel-normal-price{
                        color: $discount-color !important;
                        text-decoration: line-through;
                        font-weight: 600;
                        font-size: .8em;
                        letter-spacing: 0.5px;
                    }
                    .arrival-carousel-brand{
                        font-weight: 400;
                        font-size: .9em;
                        letter-spacing: 0.5px;
                    }
                }
            }
        }
    }
    #the-store-container{
        background-color: #dfdacf;
        min-height: 70vh;
        padding: 5vh 2vh;
        #the-store-sub-container {
            flex-direction: column;
            align-items: center;
        }
        #the-store-back-bg-img,
        #the-store-text-container{
            color: #222222;
            text-align: justify;
        }
        h1 {
            font-size: 1.95rem;
        }
        #the-store-back-bg-img{
            display: flex;
            width: 80vw;
            height: 100vw;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: 0.3s;
        }
        #the-store-text-container{
            width: 90%;
            #the-store-title {
                margin: 3vh 0 2vh;
                font-size: 1.5rem;
            }
        }
       #the-store-text {
            line-height: 1.9em;
            font-size: 0.9em;
            font-weight: 400;
            letter-spacing: 0.5px;
       } 
    }
    #blog-container{
        margin: 0 0 0.25rem 0;
        padding: 0;
        #blog-sub-container{
            flex-direction: column;
        }
        #blog-back-bg-img{
            order: 1;
            width: 80vw;
            height: 120vw;
            margin: 2vh auto 2vh auto;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        #blog-text-container{
            order: 2;
            width: 80%;
            text-align: center;
            align-items: center;
            #blog-title,
            #blog-author,
            #blog-text{
                color: $gray-secondary;
            }
            #blog-title{
                margin: 5px 0;
                padding: 0;
                display: block;
                font-weight: 600;
                font-size: 1.2rem;
                text-align: center;
                color: #1d1d1d;
            }
            #blog-author{
                margin: 1vh 0 1vh 0;
                padding: 0;
                font-weight: 400;
                font-size: 0.9rem;
                color: #222222;
            }
            #blog-text {
                line-height: 1.6rem;
                font-size: 0.9rem;
                font-weight: 400;
                margin: 0;
                text-align: center;
            }
            #blog-read-more{
                background-color: transparent;
                border: 1.6px solid $gray-secondary;
                color: $gray-secondary;
                padding: 10px 60px;
                font-weight: 500;
                cursor: pointer;
                transition: .3s;
                margin: 2vh 2vh 3vh;
                font-size: 1em;
                &:hover{
                    transform: scale(1.05);
                }
            }
        }
    }
    
}